<div class="container-fluid">
  <div class="row" id="Top">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Guidelines</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Enterprise-wide development guidelines will be presented here.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Truncate">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Truncate with Ellipsis</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-4">
            <h3>Approaches to truncate lengthy text:</h3>
            <p>Use 2+ lines, when/where necessary</p>
            <p>Use the ellipsis at the end of a large amount of text <code>(...)</code></p>
            <p>Reduce the font-size, but the text must remain legible</p>
            <p><a style="cursor: pointer;" routerLink="/UiElementsComponent/Tooltips">Tooltips</a>, in most cases, would reveal all text</p>
          </div>
          <div class="col-md-4">
            <h3>When is it used?</h3>
            <p>A grid would be our most common case. Here a grid may have a row with a cell containing a larger excerpt of content. It could very well be a note left by an end user and here you would allow a case-specific amount characters to be viewable before an ellipsis <code>(...)</code> displays. The end user may then hover the entry and see the remaining content in a <a style="cursor: pointer;" routerLink="/UiElementsComponent/Tooltips">tooltip</a>. On handheld devices it will be viewable via screen tap.</p>
          </div>
          <div class="col-md-4">
            <h3>Code Snippet</h3>
            <p>Coming Soon</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Caps">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Capitalize Text</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-4">
            <p>Coming Soon</p>
          </div>
          <div class="col-md-4">
            <h3>When is it used?</h3>
            <p>Coming Soon</p>
          </div>
          <div class="col-md-4">
            <h3>Code Snippet</h3>
            <p>Coming Soon</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Timeout">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Session Timeout</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-6">
            <p>We have a standard timeout procedure of 30 minutes. At the 27 minute mark a dialogue window appears warning the user of the impending timeout with a call to action to extend their session. </p>
          </div>
          <div class="col-md-4">
            <h3>Code Snippet</h3>
            <p>Coming Soon</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Typeahead">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Typeahead</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-8">
            <p>The typeahead feature on any given input field is to improve user experience and application performance by retrieving data. </p>
            <p>Our standard typeahead protocol activates at 3 characters and returns a query of 20 results (<strong>NOTE:</strong> there can be exceptions).</p>
          </div>
          <div class="col-md-4">
            <h3>Example:</h3>
            <p>We have the typeahead feature applied in the Authorization Intake form within Coordinate and the input fields affected are Ordering Acute Physician and Attending Acute Physician. Here the end user types 3 characters into either input field and the query returns 20 Physician results. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Infinite">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Infinite Scroll &amp; Pagination</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <blockquote class="blockquote">
              <p class="mb-0">Endless scrolling saves people from having to attend to the mechanics of pagination in browsing tasks, but is not a good choice for websites that support goal-oriented finding tasks.</p>
              <footer class="blockquote-footer">Hoa Loranger, <cite title="Source Title">Nielsen Norman Group</cite></footer>
            </blockquote>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p><strong>Infinite Scrolling</strong> is a development technique that automatically loads new content as an end user scrolls down a page within an application, presenting the user with basically an endless stream of content.</p>
            <h3>When is it used?</h3>
            <p>When there is no particular task at hand other than to consume information.</p>
            <p class="ml-1"><strong>Example:</strong> <a style="cursor: pointer;" routerLink="/StructureComponent/Cards">Cards</a></p>
            <h3>When is it NOT used?</h3>
            <p>When a task-oriented user is using a particular component they have certain expectations and those are that UI interactions are to occur. In this case it not advisable to use infinite scrolling as you limit the user experience by not having all expected content (<strong>e.g.</strong> forms, input fields, call to action buttons) loaded.</p>
          </div>
          <div class="col-md-6">
            <p><strong>Pagination</strong> is the process of dividing content into discreet pages. </p>
            <h3>When is it used?</h3>
            <p>Grids are the primary consumer of pagination mechanics. You have a litany of results on any given grid and pagination serves as the appropriate method to sort through that data.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>