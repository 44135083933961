<div class="container-fluid">
  <div class="row" id="Top">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">UI Elements</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Below we have every UI element used within our applications.</p>
            <p><strong>NOTE:</strong> If there is a need to customize a component below or add a new one please reach out to <a href="mailto:uiux-group@navihealth.com">UI/UX</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Buttons">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Buttons</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-6">
            <div>
               <button mat-raised-button color="primary" type="button">Primary</button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-size: 13px &nbsp; text-transform: uppercase &nbsp; font-weight: 700</code></div>
              <div class="col-6">
                <h3>Text</h3>
                <p>White (#fff)</p>
              </div>
              <div class="col-6">
                <h3>Fill Color</h3>
                <p>Blue 400 (#0279c5)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
               <button [ngClass]="{'btn-disable-primary': primaryButtonDisabled }" mat-raised-button [disabled]="primaryButtonDisabled"  color="primary" type="button">Primary Disabled</button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-size: 13px &nbsp; text-transform: uppercase &nbsp; font-weight: 700</code></div>
              <div class="col-6">
                <h3>Text</h3>
                <p>White (#fff)</p>
              </div>
              <div class="col-6">
                <h3>Fill Color</h3>
                <p>Blue 400 (#0279c5)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
                 <button mat-stroked-button type="button">Flat</button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-size: 13px &nbsp; text-transform: uppercase &nbsp; font-weight: 700</code></div>
              <div class="col-6">
                <h3>Text</h3>
                <p>Blue 400 (#0279c5)</p>
              </div>
              <div class="col-6">
                <h3>Fill Color</h3>
                <p>None</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
              <button mat-raised-button color="primary" type="button">with Icon<i class="material-icons right icon-white">file_download</i></button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-size: 13px &nbsp; text-transform: uppercase &nbsp; font-weight: 700</code></div>
              <div class="col-4">
                <h3>Text</h3>
                <p>White (#fff)</p>
              </div>
              <div class="col-4">
                <h3>Fill Color</h3>
                <p>Blue 400 (#0279c5)</p>
              </div>
              <div class="col-4">
                <h3>Icon Color</h3>
                <p>White (#fff)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
                  <a href="#" class="icon-button"><i class="material-icons">print</i></a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-family: 'Material Icons'; &nbsp; font-size: 24px &nbsp; font-weight: normal</code></div>
              <div class="col-4">
                <h3>Icon Color</h3>
                <p>Blue 400 (#0279c5)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
                   <button mat-button disabled>Disabled</button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-12">
                <h3>Treatment</h3>
                <code>font-size: 13px &nbsp; text-transform: uppercase &nbsp; font-weight: 700</code></div>
              <div class="col-6">
                <h3>Text</h3>
                <p>Gray 500 (#6e6e6e)</p>
              </div>
              <div class="col-6">
                <h3>Fill Color</h3>
                <p>Gray 300 (#e0e0e0)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h2>Button types</h2>
            <div class="list-group">
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Raised</h3>
                  </div>
                  <p class="mb-1">A typically rectangular material button that lifts and displays ink reactions on press.</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Flat</h3>
                  </div>
                  <p class="mb-1">A button made of ink that displays ink reactions on press but does not lift.</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Icon</h3>
                  </div>
                  <p class="mb-1">Icons will be used in place of standard buttons in several cases. Quick actions on widgets as well as within grids.</p>
                </span>
            </div>
            <div class="bump"></div>
            <h2>Button usage</h2>
            <div class="list-group">
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Forms</h3>
                  </div>
                  <p class="mb-1">Raised buttons add dimension to mostly flat layouts. They emphasize functions on busy or wide spaces.</p>
                  <p class="mb-1"><strong>Save (i.e. primary action) button is disabled when required input fields are not all populated.</strong></p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Standard modals/dialogs</h3>
                  </div>
                  <p class="mb-1">The type of button used should be suited to the context in which it appears. Use flat buttons in modals/dialogs.</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Grids</h3>
                  </div>
                  <p class="mb-1">The type of button used should be suited to the context in which it appears. Use icons as buttons within grid rows.</p>
                </span>
            </div>
            <div class="bump"></div>
            <h2>Button placement</h2>
            <div class="list-group">
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Forms</h3>
                  </div>
                  <p class="mb-1">Button alignment: Left</p>
                  <p class="mb-1">Button type: Raised</p>
                  <p class="mb-1">Place the affirmative button on the left, the dismissive button on the right.</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Standard modals/dialogs</h3>
                  </div>
                  <p class="mb-1">Button alignment: Right</p>
                  <p class="mb-1">Button type: Flat</p>
                  <p class="mb-1">Place the affirmative button on the right, the dismissive button on the left.</p>
                  <br />
                  <p class="mb-1">Note: In the case of a modal containing a form please use a raised button with left alignment as you would with any other form.</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Grids</h3>
                  </div>
                  <p class="mb-1">Button alignment on row: Right</p>
                </span>
            </div>
            <div class="bump"></div>
            <h2>Button names</h2>
            <div class="list-group">
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Primary</h3>
                  </div>
                  <p class="mb-1">Save, Submit, Send, Notify, Download, Save &amp; Submit, Upload, Edit, Delete, Save as Draft, Yes</p>
                </span>
              <span class="list-group-item list-group-item-action flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1">Secondary</h3>
                  </div>
                  <p class="mb-1">Cancel, Close, Clear, No</p>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Forms">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Forms</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>All forms are to be balanced out utilizing the Bootstrap grid structure. Keep in mind user as well as business
              needs to accomodate a streamlined workflow.</p>
            <p><strong>NOTE:</strong> Always apply <code>tabindex</code> attribute to your input elements and order them at
              the advisement of your Product Owner. This detail allows the developer to customize the tabbing navigation
              order of a particular UI.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h2>Standard Input fields</h2>
          </div>
        </div>
        <form [formGroup]="reactFormExample" #reactiveform="ngForm" name="reactFormExample">
          <div class="row">

            <div class="col-md-4">
              <div>
                <mat-form-field floatLabel='always'>
                  <mat-label>First Name</mat-label>
                  <input matInput id="txtFirstName" required formControl="fname">
                  <mat-error  *ngIf="reactFormExample.get('fname').errors?.required">
                    First Name is required
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="col-md-4">
              <div>
                <mat-form-field floatLabel='always'>
                  <mat-label>Last Name (placeholder)</mat-label>
                  <input matInput type="text" id="txtLastName" placeholder="Last Name" formControl="lastName">
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-4">
               <div>
                <mat-form-field floatLabel='always'>
                <mat-label>State</mat-label>
                <mat-select placeholder="-Select-">
                   <mat-option value="null">-Select-</mat-option>
                  <mat-option *ngFor="let item of drpItems" [value]="item.id">
                    {{item.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">

              <div>
                <mat-form-field floatLabel='always'>
                  <mat-label>Middle Name (disabled)</mat-label>
                  <input matInput type="text" id="middleName" disabled formControl="middleName">
                </mat-form-field>
              </div>

               
           
          </div>
            </div>
        </form>
        <div class="row">
          <div class="col-md-12">
            <h2>Information Display Input fields</h2>
          </div>
        </div>
        <div>
<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <span class="nh-halo-label-ro" for="memberID">Member ID</span>
      <div>
        <span class="label-ro">12345xyz</span>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <span class="nh-halo-label-ro" for="memberID">DOB</span>
      <div>
        <span class="label-ro">1/1/1929</span>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <span class="nh-halo-label-ro" for="memberID">Address</span>
      <div>
        <span class="label-ro">3455 Armory Road<br />Wilmington, NC 28401</span>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <span class="nh-halo-label-ro" for="memberID">Gender</span>
      <div>
        <span class="label-ro">Female</span>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="TextMask">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Text Mask</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-6">
            <p>To be used when additional formatting of input data is required. (e.g. Phone number)</p>
            <p><a href="https://www.npmjs.com/package/angular2-text-mask" target="_blank">Source</a></p>
          </div>
          <div class="col-md-6">
            <h3>Example</h3>
            <div>
                <mat-form-field floatLabel='always'>
                <mat-label>Phone Number</mat-label>
                <input matInput id="phone" [textMask]="phoneMask" name="phone">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="DataTables">
    <div class="col">
      <div class="card-row card">
        <div class="card-title">DataTables</div>
        <div class="bump"></div>
        <div class="row">
          <div class="col">
            <p>
              DataTables is used by implementing material datable. Please follow the Getting Started documentation to implement into
              your project.
              <a href="https://material.angular.io/components/table/examples">Material Table - Getting Started</a>
            </p>
            <div class="table-responsive">   
            <table mat-table [dataSource]="dataSource" matSort  matSortDisableClear="true">

              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <!-- content1 Column -->
              <ng-container matColumnDef="content1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content one </th>
                <td mat-cell *matCellDef="let element"> {{element.content1}} </td>
              </ng-container>

              <!-- content2 Column -->
              <ng-container matColumnDef="content2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content two </th>
                <td mat-cell *matCellDef="let element"> {{element.content2}} </td>
              </ng-container>

                <!-- content3 Column -->
              <ng-container matColumnDef="content3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content three </th>
                <td mat-cell *matCellDef="let element"> {{element.content3}} </td>
              </ng-container>

                 <!-- content4 Column -->
              <ng-container matColumnDef="content4">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content four </th>
                <td mat-cell *matCellDef="let element"> {{element.content4}} </td>
              </ng-container>


               <!-- action Column -->
              <ng-container matColumnDef="action">
                        <th mat-header-cell  id="materialAction" *matHeaderCellDef ></th>
                        <td mat-cell *matCellDef="let row">
                            <div class="btn-group mr-2">
                              <img class="mdc-icon-button material-icons mr-3" src="assets/edit-icon.svg" />
                              <img class="mdc-icon-button material-icons mr-1" src="assets/delete-icon.svg" />
                              
                            </div>
                        </td>
                    </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
             <mat-paginator  id="matPaginator" class="paginator-style float-right" showFirstLastButtons hidePageSize [length]="30" [pageSize]="7" [pageSizeOptions]="[5, 10, 25, 100]">
             </mat-paginator>
             </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>
              DataTables is used by implementing material table. Please follow the Getting Started documentation to implement into
              your project.
              <a href="https://material.angular.io/components/table/examples">Material Table - Getting Started</a>
            </p>
            <div class="table-responsive">   
            <table mat-table [dataSource]="largeColumnsdataSource" matSort  matSortDisableClear="true">

              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>

              <!-- content1 Column -->
              <ng-container matColumnDef="content1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content one </th>
                <td mat-cell *matCellDef="let element"> {{element.content1}} </td>
              </ng-container>

              <!-- content2 Column -->
              <ng-container matColumnDef="content2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content two </th>
                <td mat-cell *matCellDef="let element"> {{element.content2}} </td>
              </ng-container>

                <!-- content3 Column -->
              <ng-container matColumnDef="content3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content three </th>
                <td mat-cell *matCellDef="let element"> {{element.content3}} </td>
              </ng-container>

                 <!-- content4 Column -->
              <ng-container matColumnDef="content4">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content four </th>
                <td mat-cell *matCellDef="let element"> {{element.content4}} </td>
              </ng-container>
                  <!-- content5 Column -->
              <ng-container matColumnDef="content5">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content five </th>
                <td mat-cell *matCellDef="let element"> {{element.content5}} </td>
              </ng-container>

                    <!-- content6 Column -->
              <ng-container matColumnDef="content6">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content six </th>
                <td mat-cell *matCellDef="let element"> {{element.content6}} </td>
              </ng-container>
                   <!-- content7 Column -->
              <ng-container matColumnDef="content7">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content seven </th>
                <td mat-cell *matCellDef="let element"> {{element.content7}} </td>
              </ng-container>
                 <!-- content8 Column -->
              <ng-container matColumnDef="content8">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content eight </th>
                <td mat-cell *matCellDef="let element"> {{element.content8}} </td>
              </ng-container>
                <!-- content9 Column -->
              <ng-container matColumnDef="content9">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content nine </th>
                <td mat-cell *matCellDef="let element"> {{element.content9}} </td>
              </ng-container>
               <!-- content10 Column -->
              <ng-container matColumnDef="content10">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Content ten </th>
                <td mat-cell *matCellDef="let element"> {{element.content10}} </td>
              </ng-container>


               <!-- action Column -->
              <ng-container matColumnDef="action">
                        <th mat-header-cell  id="materialAction" *matHeaderCellDef ></th>
                        <td mat-cell *matCellDef="let row">
                            <div class="btn-group mr-2">
                                <img class="mdc-icon-button material-icons mr-3" src="assets/edit-icon.svg" />
                              <img class="mdc-icon-button material-icons mr-1" src="assets/delete-icon.svg" />
                            </div>
                        </td>
                    </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedMultipleColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedMultipleColumns;"></tr>
            </table>
             <mat-paginator  id="matMultiplecolumnPaginator" class="paginator-style float-right" showFirstLastButtons hidePageSize [length]="30" [pageSize]="7" [pageSizeOptions]="[5, 10, 25, 100]">
             </mat-paginator>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Dropdown">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Dropdown Menus</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-12">
            <p><strong>NOTE:</strong> It is standard practice to list data by alpha &amp; numeric ascending order.</p>
          </div>
          <div class="col-md-6">
            <div>
               <mat-form-field floatLabel='always'>
              <mat-label>Basic Mat-Select</mat-label>
              <mat-select>
                <mat-option *ngFor="let item of drpItems" [value]="item.id">
                  {{item.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div>
               <mat-form-field floatLabel='always' class="mat-auto-search">
                <input type="text" class="position-absolute multisearch  input-search-width" placeholder="Searchable"  matInput [formControl]="myControl" [matAutocomplete]="auto">
                <img class="material-icons material-icon-line-height float-right  nh-background-color search-with-placeholder" src="assets/search-icon.svg" />
                <mat-autocomplete  autoActiveFirstOption  #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
              <form>
              <mat-form-field  floatLabel='always'>
                 <mat-label>Multi-Select</mat-label>
                <mat-select [formControl]="cities" multiple>
                  <mat-option *ngFor="let city of cities" [value]="city.id">{{city.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              </form>
            </div>
          </div>
           <div class="col-md-6">
             <div>
                <mat-form-field  floatLabel='always'>
                <mat-label>Search</mat-label>
                <input type="text" class="position-absolute input-search-width" placeholder="Search for"  matInput [formControl]="myControl" [matAutocomplete]="auto">
                <img class="material-icons material-icon-line-height float-right  nh-background-color search-with-placeholder" src="assets/search-icon.svg" />
                <mat-autocomplete  autoActiveFirstOption  #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option.name">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Radio">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Radio Buttons</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Radio Buttons are used when the user must make only one selection out of a group of items.</p>
          </div>
        </div>
        <div>
             <div class="row">
              <div class="col-md-4">
                <h3>Enable</h3><div class="bump"></div>
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button value="1">Option 1</mat-radio-button>
                  <mat-radio-button value="2">Option 2</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-md-4">
                <h3>Disabled</h3><div class="bump"></div>
                  <mat-radio-group disabled aria-label="Select an option">
                  <mat-radio-button value="1">Option 1</mat-radio-button>
                  <mat-radio-button value="2">Option 2</mat-radio-button>
                </mat-radio-group>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Checkbox">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Checkboxes</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Use checkboxes when looking for yes or no answers.</p>
          </div>
        </div>
        <div>
        <div class="row">
          <div class="col-md-3">
             <mat-checkbox>Unchecked</mat-checkbox>
          </div>
          <div class="col-md-3">
             <mat-checkbox [checked]= "true">Checked</mat-checkbox>
          </div>
          <div class="col-md-3">
             <mat-checkbox disabled>Disabled</mat-checkbox>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Slider">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Slider Input</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-8">
            <p>The Slider input control is provided by <a href="https://material.angular.io/components/slider/overview" target="_blank">Angular Material</a>.</p>
            <h3>When is it used?</h3>
            <p>If needed it allows for the end user to select a range between two end points of a particular input.</p>
          </div>
          <div class="col-md-4">
            <mat-slider thumbLabel></mat-slider>
            <br />
            <h3>Code Snippet</h3>
            <code>&lt;mat-slider thumbLabel&gt;&lt;/mat-slider&gt;</code>
            <br /><br />
            <a href="https://material.angular.io/components/slider/overview" target="_blank">Source</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Toggle">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Slide Toggle</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-8">
            <p>The Slide Toggle input control is provided by <a href="https://material.angular.io/components/slide-toggle/overview"
                target="_blank">Angular Material</a>.</p>
            <h3>When is it used?</h3>
            <p>Use primarily when only two options are presented to the end user similar to the checkbox.</p>
          </div>
          <div class="col-md-4">
            <mat-slide-toggle>Do you like bananas?</mat-slide-toggle>
            <br /><br />
            <h3>Code Snippet</h3>
            <code>&lt;mat-slide-toggle&gt;Do you like bananas?&lt;/mat-slide-toggle&gt;</code>
            <br /><br />
            <a href="https://material.angular.io/components/slide-toggle/overview" target="_blank">Source</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="DateTime">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Date &amp; Time picker</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>At this time we utilize the Time control provided by
              <a href="https://www.jqwidgets.com/angular/angular-datetimeinput/index.htm"
                target="_blank">jqWidgets</a>
            </p>
            <div class="bump"></div>
            <h2>Step 1</h2>
            <code>npm install @navihealth/jqwidgets-5.7.2</code>
          </div>
        </div>
        <div class="bump"></div>

        <div>
          <div class="row">
            <div class="col-md-4">
               <mat-form-field floatLabel="always">
                    <mat-label>Material Date picker</mat-label>
                    <input id="txtDate" placeholder="mm/dd/yyyy"  matInput [matDatepicker]="uidate">
                    <mat-datepicker-toggle matSuffix [for]="uidate">
                        <img class="calendar-icon" matDatepickerToggleIcon src="assets/calendar.svg" />
                    </mat-datepicker-toggle>
                    <mat-datepicker #uidate></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-4">
              <label class="nh-halo-label-ro" for="firstName">JqWidget Time</label>
              <!-- <jqxDateTimeInput [width]="'100%'" [height]="24" [formatString]="'T'" [showTimeButton]="true" [showCalendarButton]="false"
                [(ngModel)]="dateTime1">
              </jqxDateTimeInput> -->
            </div>
            <div class="col-md-4">
              <label class="nh-halo-label-ro" for="firstName">JqWidget Date &amp; Time</label>
              <!-- <jqxDateTimeInput [width]="'100%'" [height]="24" [formatString]="'F'" [showTimeButton]="true" [(ngModel)]="dateTime2">
              </jqxDateTimeInput> -->
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row" id="Textarea">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Rich Text Editor</span>
        <div class="bump"></div>
        <span class="card-title">Textarea</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>If you find no need for a rich text editor within your project a standard textarea sans menubar is available below.</p>
            <div>
                   <mat-form-field floatLabel='always'>
                  <mat-label>Additional Info</mat-label>
                  <textarea matInput id="txtFirstName"></textarea>
                </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Expansion">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Expansion Panel</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>We utilize the <a href="https://material.angular.io/components/expansion/overview" target="_blank">Angular Material Expansion Panel</a>              component. It is intended to group content into a tab and reduces scrolling on the UI.</p>
            <mat-accordion>
              <mat-expansion-panel   #panel1  hideToggle="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img class="material-icon-line-height panel-icon-top"src="{{panel2.expanded ? 'assets/expand_less.svg' : 'assets/expand_more.svg' }}"  />
                     <span class="label-ro">Patient Info</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    Quisque aliquam nulla non mattis condimentum
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
               <mat-expansion-panel   #panel2  hideToggle="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img class="material-icon-line-height panel-icon-top"src="{{panel2.expanded ? 'assets/expand_less.svg' : 'assets/expand_more.svg' }}"  />
                     <span class="label-ro">Address Info</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    Duis mollis ligula et justo iaculis congue
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus aliquet vulputate quam, mattis luctus elit
                  tristique vel. Maecenas vel lacus maximus diam commodo euismod ac ut ante. Etiam at neque blandit, laoreet
                  erat quis, sollicitudin ipsum. Morbi eget libero imperdiet, scelerisque urna eget, feugiat leo. Praesent
                  pellentesque cursus diam sed iaculis. Proin viverra ipsum non leo porttitor, eu luctus sem pretium. Sed
                  at dui a magna mattis gravida id sit amet nisi. Proin venenatis, turpis vitae luctus laoreet, nibh lorem
                  vehicula lacus, ut posuere quam turpis eget justo.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Tabs">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Tabs</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>We utilize the <a href="https://material.angular.io/components/tabs/overview" target="_blank">Angular Material Tabs</a>              component. It is intended to group content into a tabular format thus allowing a more neatly organized UI for
              the end user.</p>
            <mat-tab-group>
              <mat-tab label="Tab One">
                <h2>Some tab content</h2>
                <p>Sed rutrum convallis orci, et fermentum ex blandit sed. Nulla ac feugiat odio, in viverra augue. Praesent
                  sed dignissim felis. Pellentesque volutpat sem eros, et placerat diam iaculis et. Aenean pharetra sagittis
                  facilisis. Praesent non eros posuere, tincidunt lorem in, posuere turpis. Donec vestibulum vel tortor eget
                  volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam nec erat sed ex cursus
                  congue sed ac mauris.</p>
              </mat-tab>
              <mat-tab label="Tab Two">
                <h2>Some more tab content</h2>
                <p>Proin facilisis rhoncus felis, sit amet iaculis sem placerat in. Orci varius natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Nullam at eleifend sem. Nunc malesuada, urna at laoreet
                  sagittis, ligula nisi porta dui, eget facilisis est lectus at est. Cras sit amet vulputate ligula. Sed
                  ac odio sapien. Maecenas sed congue diam, eu sodales velit.</p>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Notifications">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Notifications (Toastr)</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p><strong>NOTE:</strong> If developing an Angular 5 application please use <code>"ngx-toastr": "8.1.3"</code> within your <code>package.json</code>. For Angular 4 please reference <code>"ngx-toastr": "6.4.1-beta.0"</code>.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h3>When &amp; how is it used?</h3>
            <p>Used to notify or alert the end user without becoming a blocker for other workflows on the page.</p>
            <p>Use Toastr for short notes.</p>
            <p>Vanishes within 2-3 seconds.</p>
          </div>
          <div class="col-md-4">
            <h3>Examples:</h3>
            <p>Success Notification upon successful form submission</p>
            <p>Error Notification when end user incorrectly fills out a form</p>
          </div>
          <div class="col-md-4">
            <h3>When NOT to use Toastr:</h3>
            <p>End user edits, deletes or updates a record on any given page. Instead use <a style="cursor: pointer;" routerLink="/StructureComponent/Modal">Modal/Dialog</a>              with appropriate message prompt &amp; call to action <strong>IF</strong> business owner/stakeholder requests
              in project requirements.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
             <button color="primary" mat-raised-button  type="button" (click)="onSuccess()">Success</button>
          </div>
          <div class="col-md-3">
             <button color="primary" mat-raised-button  type="button" (click)="onError()">Error</button>
          </div>
          <div class="col-md-3">
             <button color="primary" mat-raised-button  type="button" (click)="onWarning()">Warning</button>
          </div>
          <div class="col-md-3">
             <button color="primary" mat-raised-button  type="button" (click)="onInfo()">Info</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Tooltips">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Tooltips</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-6">
            <p>To be used when more information would be helpful to the end user on a particular element on the application
              page. </p>
            <p><a href="https://material.angular.io/components/tooltip/overview" target="_blank">Source</a></p>
          </div>
          <div class="col-md-6">
            <h3>When is it used?</h3>
            <p>Acronyms are commonly used across our applications.. a tooltip would be helpful in this instance.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>
<span matTooltip="..on the bottom">I have a tooltip..</span>
            </div>
          </div>
          <div class="col-md-6">
            <div>
               <button color="primary" mat-raised-button  type="button" matTooltip="..on top!" matTooltipPosition="above">And I have one..</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Loader">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Page Loader (Branded)</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-8">
            <p>To be used upon entry into any application.</p>
            <p>Not to be used upon form submission. Slim Loader will be used in that instance.</p>
            <p><strong>NOTE:</strong> The logo symbol within the loader will size according to its viewport. </p>
          </div>
          <div class="col-md-4">
            <!-- Button trigger Loader modal -->
             <button color="primary" mat-raised-button  type="button" data-toggle="modal" data-target="#nHLoader">
							Launch demo
						</button>

            <!-- Modal -->
            <div class="modal fade" id="nHLoader" tabindex="-1" role="dialog" aria-labelledby="nHLoader" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h2>Page Loader (Branded)</h2>
                    <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
									</button>
                  </div>
                  <div class="modal-body">
                    <div>
      <div id="loader-wrapper">
        <div id="logo-wrapper">
          <img src="https://esdnonprod.navihealth.us/assets/logo.svg" id="logo-preloader">
        </div>
        <div id="loader">
        </div>
      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                   <button mat-stroked-button type="button" data-dismiss="modal">CLOSE</button>
                   <button color="primary" mat-raised-button  type="button" data-dismiss="modal">OK</button>
                  </div>
                </div>
              </div>
            </div>
            <script>
              $(document).ready(function () {
                $("#myModal").modal();
              });
            </script>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Slim">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Slim Loading Bar</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-8">
            <p>Loading bar at the very top of the page of your application.</p>
            <p><a href="https://www.npmjs.com/package/ng2-slim-loading-bar" target="_blank">Source</a></p>
            <h3>When is it used?</h3>
            <p>During loading and saving of data.</p>
          </div>
          <div class="col-md-4">
            <!-- Button trigger Loader modal -->
             <button color="primary" mat-raised-button  type="button" (click)="onStart()"  data-toggle="modal" data-target="#slim">
							Demo w/ code snippet
						</button>
            <!-- Modal -->
            <div class="modal fade" id="slim" tabindex="-1" role="dialog" aria-labelledby="slim" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h2>Slim Loading Bar Demo</h2>
                    <button type="button" class="close" (click)="onClose()" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
									</button>
                  </div>
                  <div class="modal-footer">
                  <button mat-stroked-button type="button" (click)="onClose()" data-dismiss="modal">Close</button>
                   <button mat-raised-button color="primary" (click)="onClose()" data-dismiss="modal">Ok</button>
                  </div>
                </div>
              </div>
            </div>
            <script>
              $(document).ready(function () {
                $("#slim").modal();
              });
            </script>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>