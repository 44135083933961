import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Page components
import { WelcomeComponent } from './welcome/welcome.component';
import { StructureComponent } from './structure/structure.component';
import { StyleComponent } from './style/style.component';
import { UiElementsComponent } from './ui-elements/ui-elements.component';
import { PersonasComponent } from './personas/personas.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { PrototypeComponent } from './prototype/prototype.component';

const routes: Routes = [
  {
    path: 'WelcomeComponent',
    component: WelcomeComponent
  },
  {
    path: 'StructureComponent/:topic',
    component: StructureComponent
  },
  {
    path: 'StyleComponent/:topic',
    component: StyleComponent
  },
  {
    path: 'UiElementsComponent/:topic',
    component: UiElementsComponent
  }, 
  {
    path: 'PersonasComponent/:topic',
    component: PersonasComponent
  },
  {
    path: 'GuidelinesComponent/:topic',
    component: GuidelinesComponent
  },
  {
    path: 'PrototypeComponent/:topic',
    component: PrototypeComponent
  },
  {
    path:'**',
    component:WelcomeComponent
  }
  /*{
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }*/
]; 
/*
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [],
  exports: [RouterModule]
})*/

export const appRoutingProviders: any[] = [
];

export const routing = RouterModule.forRoot(routes);

export class AppRoutingModule { }