import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {State, Content} from './ui-elements.model';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

const ELEMENT_DATA: State[] = [
  {id: 1, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 2, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 3, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 4, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 5, content1: 'content 1', content2: 'content for column two', content3: 'content for column three content for column three content for column three', content4: 'content for column four'},
  {id: 6, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 7, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 8, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 9, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'},
  {id: 10, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four'}
];


const ELEMENT_DATA_Content: Content[] = [
  {id: 1, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 2, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 3, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 4, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 5, content1: 'content 1', content2: 'content for column two', content3: 'content for column three content for column three content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 6, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 7, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 8, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 9, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'},
  {id: 10, content1: 'content 1', content2: 'content for column two', content3: 'content for column three', content4: 'content for column four',content5: 'content 1', content6: 'content for column two', content7: 'content for column three', content8: 'content for column four', content9: 'content for column three', content10: 'content for column four'}
];

@Component({
  selector: 'app-ui-elements',
  templateUrl: './ui-elements.component.html',
  styleUrls: ['../../assets/css/custom.css', './ui-elements.component.css']
})

export class UiElementsComponent implements OnInit {
  reactFormExample: UntypedFormGroup;
  displayedColumns: string[] = ['id', 'content1','content2','content3','content4','action'];
  displayedMultipleColumns: string[] = ['id', 'content1','content2','content3','content4','content5','content6','content7','content8','content9','content10','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  largeColumnsdataSource = new MatTableDataSource(ELEMENT_DATA_Content);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sortMultiple: MatSort;
  @ViewChild(MatPaginator) paginatorMultiple: MatPaginator;
  //@ViewChild('myDateTimeInput') myDateTimeInput; jqxDateTimeInputComponent;

  public phoneMask = { mask: ['(', /[1-9]/, /\d/, /\d/, ')',' ', /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]};

  jqDateTimeSource: any;
  jqDateTimeComponentCode: string;
  jqDateTimeModuleCode: string;
  myControl = new UntypedFormControl();
  primaryButtonDisabled: boolean = true;
  filteredOptions: any;
  dateTime1: Date = new Date();
  dateTime2: Date = new Date();
  constructor(public toastr: ToastrService, vcr: ViewContainerRef, private route: ActivatedRoute,
  private formBuilder: UntypedFormBuilder) {
  }
  private fragment: string;
  cities = [
    { id: 3, name: 'Atlanta' },
    { id: 6, name: 'Boston' },
    { id: 4, name: 'Chicago' },
    { id: 2, name: 'Louisville' },
    { id: 1, name: 'Nashville' },
    { id: 7, name: 'New York City' },
    { id: 8, name: 'San Francisco' },
    { id: 5, name: 'St Louis' }
  ];

  drpItems = [
    { id: 3, text: 'Atlanta' },
    { id: 6, text: 'Boston' },
    { id: 4, text: 'Chicago' },
    { id: 2, text: 'Louisville' },
    { id: 1, text: 'Nashville' },
    { id: 7, text: 'New York City' },
    { id: 8, text: 'San Francisco' },
    { id: 5, text: 'St Louis' }
  ];



  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.largeColumnsdataSource.sort = this.sortMultiple;
    this.largeColumnsdataSource.paginator = this.paginatorMultiple;
    this.reactFormExample = this.formBuilder.group({
      fname: new UntypedFormControl('', Validators.required),
      middleName: new UntypedFormControl({ value: '', disabled:true}, null),
      lastName: new UntypedFormControl(null)
     
  });
    this.route.params.subscribe(params => {
      this.fragment = params['topic'];

      try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) { }
    });
    this.filteredOptions = this.cities;
    this.myControl.valueChanges.subscribe(newValue=>{
    this.filteredOptions = this._filter(newValue);
    });
  }

  _filter(value: string): any{
    const filterValue = value.toLowerCase();
    const s =this.cities.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0)
    return this.cities.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
 

  onClose() {
  }
  onStart(){
  }

  onSuccess() {
    this.toastr.success('You are awesome.', 'Congrats!');
  }

  onError() {
    this.toastr.error('There was an error.', 'Oops!');
  }

  onWarning() {
    this.toastr.warning('You are being warned.', 'Alert!');
  }

  onInfo() {
    this.toastr.info('Just some information for you.', 'Thanks!');
  }

  onChanged(event) {
  }

  public buildjqDateTime() {
    // this.jqDateTimeModuleCode = `
    // import { jqxDateTimeInputComponent } from 'jqwidgets-framework/jqwidgets-ts/angular_jqxdatetimeinput';
    
    // declarations: [ jqxDateTimeInputComponent ],`;
  }

  getCustomEditorToolBar(): any {
    const toolbar = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': new Array<any>() }, { 'background': new Array<any>() }],          // dropdown with defaults from theme
        [{ 'font': new Array<any>() }],
        [{ 'align': new Array<any>() }],

        ['clean'],                                         // remove formatting button

        ['link']                         // link and image, video
      ]
    };

    return toolbar;
  }

    unmask(event) {
    if (event != null) {
      return event.replace(/\D+/g, '');
    }
  }

}