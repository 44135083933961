import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['../../assets/css/custom.css']
})
export class StructureComponent implements OnInit {
  // CLASS level variable to hold the source code details while its rendering through a TAB view
  jqxGridSource: any;
  logoutUrl: string = '';
  todayDate: Date = new Date();
  userName: string = 'John Doe';
  
  constructor(private route: ActivatedRoute) { 
      // Method where we can set/Harcode the code snippet details around any control that we need to ESDfiy
      this.SetJQXGridCodeSnippet();    
    }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.fragment = params['topic'];

        try {
          document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { }

        console.log(this.fragment);
      });
    }

    private fragment: string;

  /*Grid Code snippet Section starts*/
  public SetJQXGridCodeSnippet() {
    // const gridComponentCode = `source: any =
    //   {
    //     datatype: 'xml',
    //     datafields: [
    //       { name: 'ProductName', type: 'string' },
    //       { name: 'QuantityPerUnit', type: 'int' },
    //       { name: 'UnitPrice', type: 'float' },
    //     ],
    //     root: 'Products',
    //     record: 'Product',
    //     id: 'ProductID',
    //     url: '../assets/products.xml'
    //   };
    
    //   dataAdapter: any = new jqx.dataAdapter(this.source);
    
    //   columns: any[] =
    //   [
    //     { text: 'Product Name', columngroup: 'ProductDetails', datafield: 'ProductName', width: 250 },
    //     { text: 'Quantity per Unit', columngroup: 'ProductDetails', datafield: 'QuantityPerUnit', cellsalign: 'right', align: 'right' },
    //     { text: 'Unit Price', columngroup: 'ProductDetails', datafield: 'UnitPrice', align: 'right', cellsalign: 'right', cellsformat: 'c2' },
    //   ];
    
    //   columngroups: any[] =
    //   [
    //     { text: 'Product Details', align: 'center', name: 'ProductDetails' }
    //   ];`;

    //   this.jqxGridSource = [{
    //     name: 'app.component.ts',
    //     rawCode: gridComponentCode,
    //   }, {
    //     name: 'app.component.css',
    //     language: 'css',
    //     rawCode: 'banana {color: red;}'
    //   }];

  }

  logout() {
    console.log('Logout');
  }

  /*Grid Code snippet Section Ends*/

}
