<div class="container-fluid">
		<div class="row" id="Language">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Language &amp; Typography</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-8">
						<p>Second person, "you" or "your"</p>
						<p>Write in small, scannable segments to facilitate navigation and discovery.</p>
						<p>Keep your sentences and phrases short, with as few concepts as possible.</p>
						<p>Use the present tense to describe product behavior.</p>
						<p>Pick common words that are clearly and easily understandable to both beginning and advanced English readers.</p>
						<p>Tone - Be friendly, respectful, and focus on the user.</p>
						<p>For every message, ask yourself: does the user really need to know this?</p>
						<p>Use sentence-style caps for all titles, headings, labels, menu items. Avoid capitalizing all letters except where the style guide requires them, such as the Button style.</p>
					</div>					
					<div class="col-md-4">
						<code>body &#123; font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif &#125;</code>
						<div class="bump"></div>
						<h1>Heading h1</h1>
						<code>&lt;h1&gt;Heading h1&lt;/h1&gt;</code>
						<div class="bump"></div>
						<h2>Heading h2</h2>
						<code>&lt;h2&gt;Heading h1&lt;/h2&gt;</code>
						<div class="bump"></div>
						<h3>Heading h3</h3>
						<code>&lt;h3&gt;Heading h1&lt;/h3&gt;</code>
						<div class="bump"></div>
						<h4>Heading h4</h4>
						<code>&lt;h4&gt;Heading h4&lt;/h1&gt;</code>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Color">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Colors</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-12">
								<h3>Grays</h3>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2">
								<p style="background-color:#101010;padding:25px;"></p>
								<h4>Gray 900</h4>
								<p>#101010</p>
								<h3>Uses</h3>
								<p>Text</p>
								<p>Toast &amp; Tooltips</p>
								<p>Non-clickable Icons</p>
							</div>
							<div class="col-md-2">
								<p style="background-color:#6e6e6e;padding:25px;"></p>
								<h4>Gray 500</h4>
								<p>#6e6e6e</p>
								<h3>Uses</h3>
								<p>Disabled Text Button</p>
								<p>Disabled Toggle Icon</p>
								<p>Alternate Icon</p>
								<p>Form Bottom Border</p>
							</div>
							<div class="col-md-2">
								<p style="background-color:#e0e0e0;padding:25px;"></p>
								<h4>Gray 300</h4>
								<p>#e0e0e0</p>
								<h3>Uses</h3>
								<p>Disabled Background</p>
								<p>Button</p>
								<p>Icon Hover Background</p>
								<p>Dividers</p>
								<p>Table Borders</p>
							</div>
							<div class="col-md-2">
								<p style="background-color:#eeeeee;padding:25px;"></p>
								<h4>Gray 200</h4>
								<p>#eeeeee</p>
								<h3>Uses</h3>
								<p>Background</p>
							</div>
							<div class="col-md-2">
								<p style="background-color:#f5f5f5;padding:25px;"></p>
								<h4>Gray 100</h4>
								<p>#f5f5f5</p>
								<h3>Uses</h3>
								<p>Disabled Card</p>
								<p>Table Hover Background</p>
							</div>
							<div class="col-md-2">
								<p style="background-color:#ffffff;padding:25px; border:1px solid #eeeeee;"></p>
								<h4>White</h4>
								<p>#ffffff</p>
								<h3>Uses</h3>
								<p>Reverse Text</p>
								<p>Card</p>
								<p>Buttons</p>
								<p>Reverse Icons</p>
								<p>Subnavs</p>
							</div>

						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
							<div class="row">
								<div class="col-md-12">
									<h3>Brand colors</h3>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#0057b8;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">nH Blue</h4>
									<p>#0057b8</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Navbar</p>
									<p class="t-margin-zero">Table Headers</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#29abbd;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">nH Blue-Green</h4>
									<p>#29abbd</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Marketing</p>
									<p class="t-margin-zero b-margin-zero">Accent Color</p>
									<p class="t-margin-zero">Cards</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#e35205;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">nH Orange</h4>
									<p>#e35205</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">UI/Marketing</p>
									<p class="t-margin-zero">UI Accents</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#f79421;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">nH Yellow Orange</h4>
									<p>#f79421</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">UI/Marketing</p>
									<p class="t-margin-zero">UI Accents</p>
									<p class="t-margin-zero">Warning Text/Messages</p>
								</div>
							</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
							<div class="row">
								<div class="col-md-12">
									<h3>Utility colors</h3>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#2196f3;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">Blue 400</h4>
									<p>#2196f3</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Buttons</p>
									<p class="t-margin-zero">Links</p>
									<p class="t-margin-zero">Icons</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#36ad8b;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">Green</h4>
									<p>#36ad8b</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Positive Text/Message</p>
									<p class="t-margin-zero b-margin-zero">Accent Color</p>
									<p class="t-margin-zero">Cards</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#f15e47;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">Red</h4>
									<p>#f15e47</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Negative Text/Message</p>
									<p class="t-margin-zero">Validation Error</p>
								</div>
								<div class="col-md-3">
									<p class="b-margin-zero" style="background-color:#ffc600;padding:25px;"></p>
									<h4 class="t-margin-zero b-margin-zero">Yellow</h4>
									<p>#ffc600</p>
									<h3>Uses</h3>
									<p class="b-margin-zero">Pending Status</p>
								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Logos">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Logos</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>We have provided our logos below in horizontal and vertical formats for use on various background colors. If you need another size simply change the width attribute only and delete the height altogether.</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<h3>Wide Full Color - White Background</h3>
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-color-wd.svg" width="288" height="104" />
						</div>
						
					</div>
					<div class="col-md-6">
						<h3>Square Full Color - White Background</h3>
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-color-sq.svg" width="288" height="288" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<h3>Wide Black - White Background</h3>
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-black-wd.svg" width="288" height="104" />
						</div>
					</div>
					<div class="col-md-6">
						<h3>Square Black - White Background</h3>
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-black-sq.svg" width="288" height="288" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<h3>Wide White - Dark Background</h3>
						<div class="black-bg">
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-white-wd.svg" width="288" height="104" /></div>
						</div>
					</div>
					<div class="col-md-6">
						<h3>Square White - Dark Background</h3>
						<div class="black-bg">
						<div>
<img src="https://esdnonprod.navihealth.us/assets/nH-Logo-white-sq.svg" width="288" height="288" /></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Icons">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Icons</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>We utilize <a href="https://material.io/icons/" target="_blank">Google Material icon library</a> and below are several we use predominantly throughout our apps. In the case where you need to locate another icon you can visit <a href="https://material.io/icons/" target="_blank">Google Material</a> and find what you need. Please be advised that you must have the icon approved by UI/UX if it not found below.</p>
						<code>&lt;link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"&gt;</code>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">add</i>&nbsp;<code>&lt;i class="material-icons">add&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">delete</i>&nbsp;<code>&lt;i class="material-icons">delete&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">face</i>&nbsp;<code>&lt;i class="material-icons">face&lt;/i&gt;</code></div>				
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">file_download</i>&nbsp;<code>&lt;i class="material-icons">file_download&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">history</i>&nbsp;<code>&lt;i class="material-icons">history&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">today</i>&nbsp;<code>&lt;i class="material-icons">today&lt;/i&gt;</code></div>		
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">expand_less</i>&nbsp;<code>&lt;i class="material-icons">expand_less&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">expand_more</i>&nbsp;<code>&lt;i class="material-icons">expand_more&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">refresh</i>&nbsp;<code>&lt;i class="material-icons">refresh&lt;/i&gt;</code></div>			
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">menu</i>&nbsp;<code>&lt;i class="material-icons">menu&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">exit_to_app</i>&nbsp;<code>&lt;i class="material-icons">exit_to_app&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">report_problem</i>&nbsp;<code>&lt;i class="material-icons">report_problem&lt;/i&gt;</code></div>			
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">search</i>&nbsp;<code>&lt;i class="material-icons">search&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">create</i>&nbsp;<code>&lt;i class="material-icons">create&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">close</i>&nbsp;<code>&lt;i class="material-icons">close&lt;/i&gt;</code></div>
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">fullscreen_exit</i>&nbsp;<code>&lt;i class="material-icons">fullscreen_exit&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">fullscreen</i>&nbsp;<code>&lt;i class="material-icons">fullscreen&lt;/i&gt;</code></div>	
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">send</i>&nbsp;<code>&lt;i class="material-icons">send&lt;/i&gt;</code></div>
				</div>
				<div class="row">
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">dashboard</i>&nbsp;<code>&lt;i class="material-icons">dashboard&lt;/i&gt;</code></div>
					<div class="col-md-4 d-flex justify-content-center"><i class="material-icons">archive</i>&nbsp;<code>&lt;i class="material-icons">archive&lt;/i&gt;</code></div>	
				</div>
			</div>
		</div>
	</div>
</div>