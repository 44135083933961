import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['../../assets/css/custom.css']
})

export class WelcomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
