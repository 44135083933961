
import {throwError as observableThrowError, Observable, from} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ExmplifySourceService {

  constructor() { }

  getSource (src): Observable<any> {

      return from(new Promise((resolve, reject) => {
          let xhr = new XMLHttpRequest();
          xhr.onreadystatechange = function () {
              if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                      resolve(xhr.response)
                  } else {
                      reject(xhr.response)
                  }
              }
          };
          xhr.open("GET",src);
          xhr.send();
      }));
  }
  private handleError (error: any) {
    let errMsg: string;
    if (error) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg);
  }


}
