<link rel="stylesheet"  type="text/css"  href="/assets/css/nh-style.css"> 
<link rel="stylesheet"  type="text/css" href="/assets/css/nh-material-style.css">

<div id="wrapper">

<div id="sidebar-wrapper">

    <ul class="sidebar-nav">
        <li class="sidebar-brand text-center">
<img src="../assets/nH-Logo-color-wd.svg" width="206" />
        </li>
        <li class="cs-bold-text">
            <a style="cursor: pointer;" routerLink="/WelcomeComponent/Top">
                Welcome
            </a>
        </li>
		<li class="cs-bold-text">
            <a style="cursor: pointer;" routerLink="/StructureComponent/Top" >
                Structure
            </a>
          <ul>
            <li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Master">
                Master Header
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Navigation">
                Navigation
              </a>  
            </li>
			<li>
              <a style="cursor: pointer;"  routerLink="/StructureComponent/Grid" >
                Grid
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Cards" >
                Cards
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Modal">
                Modal &amp; Dialogs
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Widget">
                Widget Framework
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/StructureComponent/Footer">
                Footer
              </a>
            </li>
          </ul>
        </li>
		<li>
            <a style="cursor: pointer;" routerLink="/StyleComponent/Top" >
                Style
            </a>
          <ul>
            <li>
              <a style="cursor: pointer;" routerLink="/StyleComponent/Language" >
                Language &amp; Typography
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/StyleComponent/Color">
                Color
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/StyleComponent/Logos">
                Logos
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/StyleComponent/Icons">
                Icons
              </a>
            </li>
          </ul>
        </li>
          <li>
            <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Top">
                Guidelines
            </a>
          <ul>
            <li>
              <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Truncate">
                Truncate with Ellipsis
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Caps">
                Capitalize Text
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Timeout">
                Session Timeout
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Typeahead">
                Typeahead
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/GuidelinesComponent/Infinite">
                Infinite Scroll &amp; Pagination
              </a>
            </li>
          </ul>
        </li>
		<li>
            <a style="cursor: pointer;" routerLink="/UiElementsComponent/Top">
                UI Elements
            </a>
          <ul>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Buttons">
                Buttons
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Forms">
                Forms
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Forms">
                Input Fields
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/TextMask">
              Text Mask
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/DataTables">
              DataTables
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Dropdown">
                Dropdown Menus
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Radio">
                Radio Buttons
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Checkbox">
                Checkboxes
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Slider">
                Slider Input
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Toggle">
                Slide Toggle
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/DateTime">
                Date &amp; Time Picker
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Textarea">
                Rich Text Editor &#43; Textareas 
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Expansion">
                Expansion Panel
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Tabs">
                Tabs
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Notifications">
                Notifications
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Tooltips">
                Tooltips
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Loader">
                Page Loader (Branded)
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/UiElementsComponent/Slim">
                Slim Loading Bar
              </a>
            </li>
          </ul>
        </li>

        
          
        <li>
          <a style="cursor: pointer;" routerLink="/PrototypeComponent/Top">
            Prototyping
          </a>
          <ul>
            <li>
              <a style="cursor: pointer;" routerLink="/PrototypeComponent/Top">
                Overview
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/PrototypeComponent/Brentwood">
                Brentwood Campus
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/PrototypeComponent/Newton">
                Newton Campus
              </a>
            </li>
          </ul>
        </li>



        <li class="cs-bold-text">
            <a style="cursor: pointer;" routerLink="/PersonasComponent/Top">
                User Personas
            </a>
          <ul>
            <li>
              <a style="cursor: pointer;" href="https://dev-web-02.curaspan.local/personas/" target="_blank">
                nH Discharge
              </a>
            </li>
            <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Identify">
                nH Identify
              </a>  
            </li>
			<li>
              <a style="cursor: pointer;"  routerLink="/PersonasComponent/Coordinate">
                nH Coordinate
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Predict">
                nH Predict
              </a>
            </li>
			<li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Perform">
                nH Perform
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Intake">
                nH Intake
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Ride">
                nH Ride
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Review">
                nH Review
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Engage">
                nH Engage
              </a>
            </li>
      <li>
              <a style="cursor: pointer;" routerLink="/PersonasComponent/Insight">
                nH Insight
              </a>
            </li>
          </ul>
        </li>
		</ul>
</div>

<div class="layout-view">
	<router-outlet></router-outlet>
</div>

</div> 
