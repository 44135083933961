import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { StructureComponent } from './structure/structure.component';

import { AppRoutingModule, routing, appRoutingProviders } from './app.routing';
import { StyleComponent } from './style/style.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UiElementsComponent } from './ui-elements/ui-elements.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { ToastrModule } from 'ngx-toastr';
import { PersonasComponent } from './personas/personas.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExemplifyModule } from '../exemplify/exemplify.module';
import { PrototypeComponent } from './prototype/prototype.component';
// import { jqxDateTimeInputComponent } from 'jqwidgets-framework/jqwidgets-ts/angular_jqxdatetimeinput';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { MatIconModule, MatIcon } from '@angular/material/icon';

import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatNativeDateModule, DateAdapter } from "@angular/material/core";
import { MatLegacyOptionModule as MatOptionModule } from "@angular/material/legacy-core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    StructureComponent,
    StyleComponent,
    WelcomeComponent,
    UiElementsComponent,
    PersonasComponent,
    GuidelinesComponent,
    PrototypeComponent,
    //jqxDateTimeInputComponent
  ],
  imports: [
    BrowserModule,
    routing,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatTooltipModule,
    MatSliderModule,
    ToastrModule.forRoot(),
    MatSlideToggleModule,
    NgSelectModule,
    ReactiveFormsModule,
    ExemplifyModule,
    FormsModule,
    DataTablesModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    MatToolbarModule,
    MatListModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatRadioModule,
    MatIconModule
  ],
  providers: [appRoutingProviders],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
