<div class="container-fluid">
  <div class="row" id="Top">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">nH User Personas</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>At this time we have our application suite detailed out below and User Personas for each are forthcoming. Our intent is to gather around the users of our applications, get to know them and relay their role and workflow within the naviHealth organization. Stay tuned!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Discharge">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Discharge</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Embedded workflow tool to standardize and streamline transitions of care by matching patients with appropriate
              and available providers throughout a robust database, create operational efficiencies with electronic communication,
              and reduce process variation to accomplish discharges more effectively.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Identify">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Identify</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Proprietary peer-reviewed, clinically-validated, evidence-based assessment tool to identify patients' need for
              post-acute care, risk of readmission, and provide a level-of-care recommendation based on information collected
              at admission.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Coordinate">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Coordinate</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Primary tool used by naviHealth Clinicians and nH Predict licensing partners to manage and evaluate patients,
              complete nH Predict, process authorizations, facilitate discharge planning, and document interventions and
              engagement. It also provides the capability for naviHealth managers to view and administer staff updates as
              necessary. In addition, data captured is used to generate statistics around savings and patient population
              trends.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Predict">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Predict</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Proprietary and evidence-based decision support solution that generates individualized patient-centered care plans
              and optimizes patient results.</p>
            <h3>Function</h3>
            <p>Assessment tool that returns current function scores, used to set a baseline measure for tracking progress against
              predicted outcomes.</p>
            <h3>Outcome</h3>
            <p>Predictive analytics databse of post-acute care outcomes for each patient, adding data-driven support to clinical
              decision making, and aligning expectations across the care team, patient, and family.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Perform">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Perform</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Proprietary tool that enables providers to rank and trend SNF performance using a common set of scoring criteria to assess quality, effectiveness, and operations.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Intake">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Intake</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>A referral management platform built for post-acute providers to enable real-time communication and information-sharing across a connected network.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Ride">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Ride</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Streamlined coordination of non-emergent patient transportation post-discharge to send and receive rides electronically.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Review">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Review</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Streamlined workflow and audit trail of case submissions to facilitize utilization review processes and communication between hospitals and payors.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Engage">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Engage</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Software that enables providers and care navigators to connect with patients pre-admission and post discharge via phone, SMS, computer, or video to monitor patient progress.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="Insight">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title"><span class="naviO">nH</span> <span class="naviB">Insight</span></span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>Enhanced reporting capabilities to provide transparency across the continuum, gain visibility into hospital operations, and connect with high-performing providers.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Name / Role
                  </mat-panel-title>
                  <mat-panel-description>
                    Role description
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <p>Praesent semper ut lacus id pharetra. In at quam fermentum, faucibus dui sit amet, porttitor diam. Pellentesque
                  vehicula est id condimentum gravida. Praesent velit nulla, consequat id volutpat quis, tempor vitae mauris.
                  Mauris eget leo fermentum, mollis erat id, convallis dui. In hac habitasse platea dictumst.</p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>