import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-prototype',
  templateUrl: './prototype.component.html',
  styleUrls: ['./prototype.component.css']
})
export class PrototypeComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  private fragment:string;

  ngOnInit() {
    this.route.params.subscribe(params=>{
      this.fragment = params['topic'];
       try {
        document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { }
    });
  }

}