<div class="container-fluid">
	<div class="row" id="Top">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Structure</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>All application pages are coded using Bootstrap 4.0 methodologies. Below you can find several basic examples. Your
							application may require a more advanced layout and if that is the case you will work more closely with your UI/UX
							Designer developing the interface.</p>
						<div class="row row-style-grid">
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
							<div class="col-md-1 column-style-grid">.col-md-1</div>
						</div>
						<div class="row row-style-grid">
							<div class="col-md-8 column-style-grid">.col-md-8</div>
							<div class="col-md-4 column-style-grid">.col-md-4</div>
						</div>
						<div class="row row-style-grid">
							<div class="col-md-4 column-style-grid">.col-md-4</div>
							<div class="col-md-4 column-style-grid">.col-md-4</div>
							<div class="col-md-4 column-style-grid">.col-md-4</div>
						</div>
						<div class="row row-style-grid">
							<div class="col-md-6 column-style-grid">.col-md-6</div>
							<div class="col-md-6 column-style-grid">.col-md-6</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Master">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Master Header</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<div class="bump">
						</div>
						<h3>Header Option 1</h3>
						<h4>Logo and App Name</h4>
						<div>
							<nav class="navbar navbar-brand">
								<img class="" src="https://esdnonprod.navihealth.us/assets/nH-icon.svg" height="68px">
								<a href="#" class="master-header-title">nH Application</a>
							</nav>
						</div>

						<div class="bump"></div>

						<h3>Header Option 2</h3>
						<h4> Logo, App Name, "Welcome, (User)", and Logout Button</h4>
						<div>
							<nav class="navbar navbar-brand">
								<img class="" src="https://esdnonprod.navihealth.us/assets/nH-icon.svg" height="68px">
								<a href="#" class="master-header-title">nH Application</a>
								<div class="float-right">
									<div class="row">
										<div class="col-md-auto">
											<p class="welcome header-margin-right">Welcome, Super Long Fakename {{userName}}</p>
										</div>
										<div class="col-md-auto spacer">
											<a href="{{logoutUrl}}" alt="Log Out" (click)="logout()">
												<i class="material-icons reverse">exit_to_app</i>
											</a>
										</div>
									</div>
								</div>
							</nav>
						</div>

						<div class="bump"></div>

						<h3>Header Option 3</h3>
						<h4> Logo, App Name, "Welcome, (User)", Date/Time, and Logout Button</h4>
						<div>
							<nav class="navbar navbar-brand">
								<img class="" src="https://esdnonprod.navihealth.us/assets/nH-icon.svg" height="68px">
								<a href="#" class="master-header-title">nH Application</a>
								<div class="float-right">
									<div class="row">
										<div class="col-md-auto">
											<p class="header-3">Welcome, Super Long Fakename {{userName}}</p>
											<p class="float-right header-3-date-time">th/is/is, fa:ke {{todayDate| date: 'MM/dd/yyyy, hh:mma'}}</p>
										</div>
										<div class="col-md-auto spacer">
											<a href="{{logoutUrl}}" alt="Log Out" (click)="logout()">
												<i class="material-icons reverse">exit_to_app</i>
											</a>
										</div>
									</div>
								</div>
							</nav>
						</div>

						<!-- former header 2 that does not work in IE							 -->
						<!-- <nav class="navbar navbar-brand">
								<img class="" src="https://esdnonprod.navihealth.us/assets/nH-icon.svg" height="68px">
								<a href="#" class="master-header-title">nH Application</a>
								<div class="float-right">
									<div class="row">
										<p class="welcome">Welcome, Fakename {{userName}}</p>
										<div class="col spacer">
											<a href="{{logoutUrl}}" alt="Log Out" (click)="logout()">
												<i class="material-icons reverse">exit_to_app</i>
											</a>
										</div>
									</div>
								</div>
							</nav> -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Navigation">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Navigation</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<div>
							<div class="nh-extended-nav-panel">
								<div class="row">
									<div class="col-md-12">
										<button class="btn-flat">
											Lions
										</button>
										<button class="btn-flat">
											Tigers
										</button>
										<button class="btn-flat">
											Bears
										</button>
										<button class="btn-flat">
											Oh My!
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Grid">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Grids</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>All grids are made up of Bootstrap 4.0 coding methods. Below are standard features of our grid systems:</p>
						<p>All grids sort in a descending manner by default (e.g. most current Date will be listed at the top).</p>
						<p>All sorting can be altered to ascending by column title if the end user wishes.</p>
						<p>All grids will have inline editing available by default. Grids having 4 columns or less will grant the user the ability
							to edit on the present UI. When there are 5 or more columns the user will select an "Edit" icon to then activate a
							modal window having all details available for editing.</p>
						<p>Grid rows can have an "Actions" column on the far right for a variety of functions. Today, those are: Edit, Save, Delete,
							Void. Those functions must also be listed in icon format utilizing one from the
							<a href="https://material.io/icons/"
							 target="_blank">Google Material icon library</a>.</p>
						<p>All grids use zebra-striping so users can scan a row more easily.</p>
					</div>
				</div>
				<div class="row">
					<Div class="col-md-12" >
                        <div>
                            <Div> Example Coming Soon</Div>
                        </div>
                    </Div>

				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Cards">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Cards</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>Cards display content composed of different elements whose size or supported actions vary. As a grouping, comprises
							multiple data types, such as text, grids, and input fields. Cards provide context and an entry point to more robust
							information and views, and their content and quantity can vary. Group the varying items for a card by purpose.</p>
						<p>Cards will always begin with a header title. Cards also have a standard dropshadow in place showing emphasis. </p>
						<p>Because every app is different, there are no "standard" layouts for card content; each app and SME should define their
							own.
						</p>
						<p>Dividers may be used to separate content areas in cards that require distinct visual separation. Dividers may also
							indicate seams in places where material may expand. When using an expansion panel always have the panel expand across
							the full width of the card.</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<br />
						<div>
							<div class="card-row card">
								<span class="card-title">Header 1</span>
								<div class="bump"></div>
								<div class="row">
									<div class="col-md-12">
										<p>Fusce sed ante suscipit, dictum nisl in, posuere eros. Etiam ultricies risus finibus, posuere nunc sed, vulputate
											eros. Vestibulum sed iaculis metus. Nam et eros magna. Mauris auctor leo lorem, eget convallis purus malesuada
											ut. Quisque tincidunt risus at rhoncus lacinia. Nullam porta auctor dolor, sed auctor lacus dapibus sit amet.
											Nullam feugiat eros orci, eu vehicula nisi vestibulum pulvinar. Cras dictum mauris sit amet tellus lacinia faucibus.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Modal">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Modal &amp; Dialog windows</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>A modal &amp; dialog is a window that forces the user to interact with it before they can go back to using the parent
							application. A great example of this would be a prompt for deleting a record. They are often used when a user is forced
							to make an important decision.
							<a href="https://v4-alpha.getbootstrap.com/components/modal/" target="_blank">Source</a>
						</p>
						<!-- Modal -->
						<div>
							<div class="modal fade" id="nHModal" tabindex="-1" role="dialog" aria-labelledby="nHModal" aria-hidden="true">
								<div class="modal-dialog">
									<div class="modal-content">
										<div class="modal-header">
											<h2>Modal title</h2>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean auctor purus nec elit molestie feugiat. Vestibulum
												ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Phasellus id elit eget lacus maximus
												lacinia eget ac sapien.</p>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn-flat" data-dismiss="modal">Close</button>
											<button type="button" class="btn-flat" data-dismiss="modal">Ok</button>
										</div>
									</div>
								</div>
							</div>
							<script>
								$(document).ready(function () {
									$("#myModal").modal();
								});
							</script>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<!-- Button trigger modal -->
						<div>
							<button type="button" class="btn waves-effect waves-light" data-toggle="modal" data-target="#nHModal">Launch modal</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Widget">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Widget Framework</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>An Angular 4 dashboard framework heavily inspiried by the
							<a href="https://github.com/angular-dashboard-framework/angular-dashboard-framework"
							 target="_blank">angular-dashboard-framework</a> project.</p>
						<p>
							<strong>WARNING:</strong> This project is under active development and will most likely contain many breaking changes until
							we hit 1.0.0</p>
						<p>
							<a href="https://bitbucket.navihealth.us/projects/WIDGETFW/repos/widgetlibrary/browse/lib" target="_blank">Browse Widget / Widget Library - BitBucket</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" id="Footer">
		<div class="col-md-12">
			<div class="card-row card">
				<span class="card-title">Footer</span>
				<div class="bump"></div>
				<div class="row">
					<div class="col-md-12">
						<p>Resides at the base of all application pages.</p>
						<div>
							<footer class="page-footer">
								<div class="footer-copyright">
									&#169; Copyright 2018, naviHealth, Inc. All Rights Reserved.
								</div>
							</footer>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>