<div class="container-fluid">
  <div class="row">
    <div class="col-md-9">
      <div class="card-row card">
        <span class="card-title">Welcome</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>We employ a hybrid design system at naviHealth. The styles and design principles here are heavily influenced
              on Google's <a href="https://material.io/" target="_blank">Material Design</a>, but slightly modified to reflect
              our branding strategy. Our application layout methods are built using the <a href="https://getbootstrap.com/"
                target="_blank">Bootstrap (v 4.1.3)</a> framework. User Interface components/elements that make up a large
              portion of our applications are from various sources in addition to <a href="https://material.angular.io/"
                target="_blank">Angular Material</a>. We will rely on these principles and documentation when designing and
              building our products.</p>
              <h2>How It Works</h2>
              <p>Coming Soon</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h2>Dependencies (Required)</h2>
            <p><strong>NOTE:</strong> It is imperative the following dependencies are referenced in your project. They will also require an Octopus configuration to transform the usage from nonprod to prod. For an implementation example, please see the <a href="https://bitbucket.navihealth.us/projects/CCMDOC/repos/documentmanagementlibrary/browse/DocumentCLILibrary" target="_blank">Document Library repo.</a></p>
            <div class="bump"></div>
            <code>https://esdnonprod.navihealth.us/assets/css/bootstrap.min.4.1.css</code>
            <div class="bump"></div>
            <code>https://esdnonprod.navihealth.us/assets/css/nh-style.css</code>
            <div class="bump"></div>
            <code>https://fonts.googleapis.com/icon?family=Material+Icons</code>
            <div class="bump"></div>
            <code>https://esdnonprod.navihealth.us/assets/js/jquery-3.3.1.min.js</code>
            <div class="bump"></div>
            <code>https://esdnonprod.navihealth.us/assets/js/bootstrap.min.js</code>
            <div class="bump"></div>
            <code>https://esdnonprod.navihealth.us/assets/js/materialize.min.js</code>
            <div class="bump"></div>
            <h3>Pendo</h3>
            <code>Coming Soon</code>
          </div>
        </div>
          <div class="row" id="Caps">
    <div class="col-md-12">
      <h2>What's Coming Next?</h2>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-4">
            <h3>User Personas</h3>
            <p>An effort to better understand the different sets of user roles and their workflows throughout our product suite is underway.. Stay tuned for more!</p>
          </div>
          <div class="col-md-4">
            <h3>UI Elements</h3>
            <p>Indeterminate state for Checkbox</p>
          </div>
          <div class="col-md-4">
            <h3>Guidelines</h3>
            <p>Pipes &amp; Filters</p>
          </div>
          <div class="col-md-4">
            <h3>Dependencies</h3>
            <p>Customizable SaSS</p>
          </div>
          <div class="col-md-4">
            <h3>Other</h3>
            <p>Pendo integration</p>
            <p>Process Diagram for "How It Works"</p>
          </div>
        </div>
    </div>
  </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card-row card">
        <span class="card-title">Angular version: 7</span>
        <div class="row">
          <div class="col-md-12">
            <div class="bump"></div>
            <img src="../assets/angular.svg" width="80%" class="mx-auto d-block" />
          </div>
        </div>
      </div>
      <div class="bump"></div>
      <div class="card-row card">
        <span class="card-title">More Information</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>For questions about UI/UX, please email:</p>
            <h2>Brentwood</h2>
            <p><a href="mailto:uiuxgroup@navihealth.us">UI/UX Group</a></p>
            <p><a href="mailto:hforsee@navihealth.us">Hayden Forsee (UI/UX)</a></p>
            <hr>
            <h2>Newton</h2>
            <p><a href="mailto:peter.lehman@navihealth.us">Peter Lehman (UX Manager)</a></p>
            <p><a href="mailto:stuart.kim@navihealth.com">Stuart Kim (UX)</a></p>
            <p><a href="mailto:amy.alsumali@navihealth.com">Amy Alsumali (UX)</a></p>
            <p><a href="mailto:mitch.soper@navihealth.com">Mitch Soper (UX)</a></p>
            <div class="bump"></div>
            <img src="../assets/nH-Logo-color-sq.svg" width="80%" class="mx-auto d-block" />
          </div>
        </div>
      </div>
    </div>
  </div>

</div>