import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['../../assets/css/custom.css']
})
export class StyleComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  private fragment:string;

  ngOnInit() {
    this.route.params.subscribe(params=>{
      this.fragment = params['topic'];
       try {
        document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { }
    });
  }
}
