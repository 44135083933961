<div class="container-fluid">
  <div class="row" id="Top">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Prototyping Process Overview</span>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-12">
            <p>At naviHealth we encourage innovation and collaboration. At the beginning of any design process, stakeholders,
              product owners/managers and developers should utilize whiteboard design, hand-drawn sketch, and/or free tools
              like Balsalmiq to create a lo-fi wireframe. The next step is to schedule a meeting with the
              <a href="https://navihealthportal.sharepoint.com/:w:/r/sites/ITDevArchitecture/Shared%20Documents/UI%20%26%20UX/UX_UI_Who_We_Are.docx?d=w37f159926ae6425b9f0266de7bccfd89&csf=1&e=N8jROt"
                target="_blank">UI/UX team</a> to review workflow and feasibility. Colleagues should not spend too much time on a hi-fi mockup
              or prototype until the UX process has been implemented, as rapid prototyping is integral to saving time and
              technical debt.
            </p>
          </div>
          <div class="col-md-12">
            <h3>Our low to high fidelity linear process follows:</h3>
            <div class="bump"></div>
            <p>
              <strong>Sketching</strong> -
              <i>Brainstorm by drawing quick and rough sketches on paper</i>
            </p>
            <div class="indented">Two to three sprints before a card is pulled into a sprint, the product owner should schedule time with a UI/UX
              designer to knowledge transfer, brainstorm, and explain the problem that needs to be solved. This can be done
              before the initial card is created. In other words, the sooner the better.
            </div>
            <div class="bump"></div>
            <div class="indented">
              See an example of a sketch
              <a href="https://navihealthportal.sharepoint.com/:w:/r/sites/ITDevArchitecture/Shared%20Documents/UI%20%26%20UX/UX_UI_Deliverables.docx?d=w01269c1ebf65461c9f2811c7bf4f3fda&csf=1&e=MbVyJK"
                target="_blank">here.</a>
            </div>
            <div class="bump"></div>
            <p>
              <strong>Wireframing/Whiteboarding</strong> -
              <i>Start laying out the skeletal framework with boxes and rough shapes</i>
            </p>
            <div class="indented">The UX/UI designer will then take the problem and wireframe several solutions. We want to avoid spending a lot
              time on high fidelity here - the intention of the wireframe is to iterate quickly. Because wireframes are more
              concerned with the broad strokes of design (such as overall layout), they usually lead to low-fidelity (no
              color) prototypes.
            </div>
            <div class="bump"></div>
            <div class="indented">
              See an example of a wireframe
              <a href="https://navihealthportal.sharepoint.com/:w:/r/sites/ITDevArchitecture/Shared%20Documents/UI%20%26%20UX/UX_UI_Deliverables.docx?d=w01269c1ebf65461c9f2811c7bf4f3fda&csf=1&e=MbVyJK"
                target="_blank">here.</a>
            </div>
            <div class="bump"></div>
            <p>
              <strong>Mockups</strong> -
              <i> Inject detail into wireframes with colors, typographies, photos, and other visual design elements</i>
            </p>
            <div class="indented">Mockups are better-dressed wireframes. Neither require functionality, but mockups give a better idea of what
              the final product will look like, and at times suggest how it will function.
            </div>

            <div class="bump"></div>
            <div class="indented">
              See an example of a mockup
              <a href="https://navihealthportal.sharepoint.com/:w:/r/sites/ITDevArchitecture/Shared%20Documents/UI%20%26%20UX/UX_UI_Deliverables.docx?d=w01269c1ebf65461c9f2811c7bf4f3fda&csf=1&e=MbVyJK"
                target="_blank">here.</a>
            </div>
            <div class="bump"></div>
            <p>
              <strong>Prototyping</strong> -
              <i>Add interactivity to mockups by stitching screens together for basic prototypes or adding animations/interactions
                for advanced prototypes</i>
            </p>
            <div class="indented"> Low-fidelity prototypes are great for quick collaboration and exploration, high-fidelity prototypes can be better
              for product definition and estimates
            </div>
            <div class="bump"></div>
            <div class="indented">
              See an example of a prototype
              <a href="https://marvelapp.com/6141c0b/screen/39102627"
                target="_blank">here.</a>
            </div>
            <div class="bump"></div>
            <p>
              <strong>UI Development</strong> -
              <i>Code in enterprise language to turn the prototype into the final product</i>
            </p>
            <div class="bump"></div>
            <div class="bump"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- BRENTWOOD CAMPUS -->
  <div class="row" id="Brentwood">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Brentwood Campus Prototyping Tools</span>
        <div class="bump"></div>
        <div class="bump"></div>
        <div class="row">
          <div class="col-md-4 line_right">
            <h3 class="centering">Wireframe/Mockups</h3>
            <div class="bump"></div>
            <li class="indented">
              <a href="https://balsamiq.com/" target="_blank">Balsamiq</a>
            </li>
            <li class="indented">Adobe Photoshop</li>
            <li class="indented">Adobe XD</li>
            <li class="indented_more">If you need access to Adobe products, please submit a <a href="mailto:ithelpdesk@navihealth.com">help desk ticket</a> requesting a license. You will need manager
              approval
            </li>
            <br/>
            <li class="indented">Adobe files should be stored in individual project repositories on <a href="https://bitbucket.navihealth.us/projects/UX" target="_blank">Bitbucket</a>
              <li class="indented_more">If you need access to certain Bitbucket repositories, please reach out to the
                <a href="mailto:uiuxgroup@navihealth.com">UI/UX team</a>
              </li>
          </div>
          <div class="col-md-4 line_right">
            <h3 class="centering">Prototyping</h3>
            <div class="bump"></div>
            <li class="indented">Option 1</li>
            <li class="indented_more">Adobe Photoshop (for screens) combined with
              <a href="https://marvelapp.com/" target="_blank">Marvel</a> (for screen linking)</li>
            <li class="indented_more">If you need access to our Enterprise Marvel account please reach out to the 
              <a href="mailto:uiuxgroup@navihealth.com">UI/UX team</a>
            </li>
            <br>
            <li class="indented">Option 2</li>
            <li class="indented_more">Adobe XD for screens and linking</li>
          </div>
          <div class="col-md-4">
            <h3 class="centering">Enterprise UI Kit</h3>
            <div class="bump"></div>
            <li class="indented">Adobe XD</li>
            <li class="indented_more">Our UI Kit contains enterprise branded elements and components in a file that you can import into your XD project
              to drag and drop already created and styled enterprise components</li>
            <li class="indented_more">Find our downloadable Adobe XD UI kit on Bitbucket <a href="https://bitbucket.navihealth.us/projects/UX/repos/uikitadobexd/browse" target="_blank">here</a></li>
            <li class="indented_more">Find instructions on how to import XD files
              <a href="https://helpx.adobe.com/xd/help/working-with-external-assets.html" target="_blank">here</a>
            </li>
            <li class="indented_more">If you need assistance with our UI Kit, please reach out to the
              <a href="mailto:uiuxgroup@navihealth.com">UI/UX team</a>
            </li>

            <div class="bump"></div>
            <li class="indented"><a href="https://www.uxpin.com/studio/blog/how-to-use-ui-kits-to-create-quicker-mockups/ "
              target="_blank">Read</a> more about the benefits of a UI Kit
              
            </li>
          </div>

        </div>
        <div class="bump"></div>
        <div class="bump"></div>
      </div>
    </div>
  </div>
  <!-- END BRENTWOOD CAMPUS -->

  <!-- NEWTON CAMPUS -->
  <div class="row" id="Newton">
    <div class="col-md-12">
      <div class="card-row card">
        <span class="card-title">Newton Campus Prototyping Tools</span>
        <div class="bump"></div>
        <div class="bump"></div>

        <div class="row">
          <div class="col-md-4 line_right">
            <h3 class="centering">Wireframe/Mockups</h3>
            <div class="bump"></div>
            <li class="indented">
              <a href="https://www.invisionapp.com/blog/freehand-creative-collaboration/" target="_blank">InVision Freehand</a>
              <div class="bump"></div>
            </li>
            <li class="indented">Sketch</li>
            <li class="indented_more">If you need access to Sketch, please reach out to
              <a href="mailto:stuart.kim@navihealth.com">Stuart Kim</a> or
              <a href="mailto:amy.Alsumali@navihealth.com">Amy Alsumali</a>
            </li>
            <div class="bump"></div>
            <li class="indented">Sketch files should be stored on the UX share drive. Please reach out to
              <a href="mailto:stuart.kim@navihealth.com">Stuart Kim</a> or
              <a href="mailto:amy.Alsumali@navihealth.com">Amy Alsumali</a> for access to their projects</li>
            <div class="bump"></div>
            <li class="indented">InVision files can be found on the enterprise InVision account</li>
            <li class="indented_more">If you need access to the share drive or the enterprise InVision account, please submit a help desk ticket
              <a href="mailto:ithelpdesk@navihealth.com">here</a> for access rights
            </li>
          </div>
          <div class="col-md-4 line_right">
            <h3 class="centering">Prototyping</h3>
            <div class="bump"></div>
            <li class="indented">Sketch (for screens)</li>
            <div class="bump"></div>
            <li class="indented">InVision (for screen linking)</li>
            <li class="indented_more">If you need to access to our Enterprise InVision projects, please reach out to Stuart Kim or Amy Alsumali</li>
          </div>
          <div class="col-md-4">
            <h3 class="centering">Enterprise UI Kit</h3>
            <div class="bump"></div>
            <li class="indented">Sketch</li>
            <li class="indented_more">Our UI Kit contains enterprise branded elements and components in a file that you can import into your Sketch
              project to drag and drop already created and styled enterprise components</li>
            <li class="indented_more">This is a work in progress, please reach out to Stuart Kim or Amy Alsumali for access to styled enterprise components</li>
            <div class="bump"></div>
          </div>
        </div>
        <div class="bump"></div>
        <div class="bump"></div>
      </div>
    </div>
  </div>
  <!-- END NEWTON CAMPUS -->
  <div class="row" id="Top">
    <div class="col-md-12">
      <div class="card-row card">
        <div class="row">
          <div class="col-md-12">
            <p>
              <i>Please note:</i>
              <br>
              <br> Newton's campus operates on Mac machines, and Brentwood's campus operate on Windows machines. For this reason,
              the two campuses have different work flows.
              <li class="indented">Newton creates screens primarily in Sketch, which is a Mac exclusive design application. </li>
              <li class="indented">Brentwood creates screens in the Adobe Suite, which is available on Windows machines.</li>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>